export interface LogoBankIDProps {
  white?: boolean
  className?: string
}

export const LogoBankID = ({ white, className }: LogoBankIDProps) => {
  return (
    <svg className={className} width="53" height="60" viewBox="0 0 53 60">
      <g clipPath="url(#clip0_206_43355)">
        <path
          d="M16.5186 31.3076L18.6776 14.6156C17.7962 14.6156 16.3205 14.6156 16.3205 14.6156C15.241 14.6156 13.8248 13.9076 13.3791 12.5396C13.2306 12.0596 12.9335 10.4636 14.7557 8.91556C15.3896 8.37556 15.8352 7.78756 15.8848 7.30756C15.9838 6.82756 15.8848 6.41956 15.5876 6.05956C15.241 5.63956 14.4586 5.35156 13.4782 5.35156C11.8144 5.35156 10.6358 6.53956 10.5368 7.36756C10.4378 8.01556 10.8834 8.49556 11.2201 8.79556C12.2501 9.74356 12.4977 11.1116 11.854 12.4196C11.1706 13.7276 9.74451 14.6156 8.22926 14.6156C8.22926 14.6156 6.714 14.6156 5.82268 14.6156C5.62461 16.2236 2.38612 41.1716 2.14844 43.1276H15.1024C15.1519 42.2996 15.7362 37.5476 16.5186 31.3076Z"
          fill={white ? "#FFFFFF" : "#459BBE"}
        />
        <path
          d="M33.8307 0H20.6886L18.9258 13.488H21.1838C22.4119 13.488 23.5904 12.84 24.0757 11.82C24.2242 11.52 24.3232 11.22 24.3232 10.992C24.3232 10.452 24.0261 9.984 23.6894 9.744C22.808 8.976 22.6594 8.136 22.6594 7.548C22.6594 7.428 22.6594 7.308 22.6594 7.248C22.8575 5.82 24.4223 4.272 26.5318 4.272C27.8093 4.272 28.7403 4.632 29.3246 5.28C29.8198 5.88 30.0574 6.708 29.9089 7.536C29.7108 8.544 28.8789 9.372 28.3936 9.792C27.1161 10.86 27.2646 11.808 27.3637 12.108C27.6112 12.936 28.6412 13.476 29.4236 13.476H32.8602C37.5149 13.536 40.0205 16.092 39.1886 22.152C38.4557 27.732 34.9201 30.168 30.7111 30.228L28.9482 43.116H31.4043C41.8427 43.116 50.3301 34.98 52.0435 22.2C54.1232 6.36 45.7943 0 33.8307 0Z"
          fill={white ? "#FFFFFF" : "#235971"}
        />
        <path
          d="M1.42612 48.8887H6.72455C8.98258 48.8887 9.51737 50.2567 9.36882 51.5647C9.22026 52.5727 8.68547 53.3527 7.65549 53.8207C8.93306 54.4207 9.41834 55.3087 9.22026 56.7367C8.97267 58.5247 7.70501 59.8207 6.0313 59.8207H0L1.42612 48.8887ZM4.90229 53.4607C5.93226 53.4607 6.41754 52.8127 6.51658 52.0327C6.61561 51.2047 6.31851 50.6047 5.28853 50.6047H4.35759L3.96145 53.4607H4.90229V53.4607ZM4.31798 58.2127C5.39747 58.2127 5.98178 57.6727 6.13034 56.6047C6.22937 55.7167 5.83323 55.1167 4.80325 55.1167H3.77328L3.37713 58.2007L4.31798 58.2127Z"
          fill={white ? "#FFFFFF" : "#235971"}
        />
        <path
          d="M16.5684 59.9411C15.1918 60.0611 14.5084 59.8811 14.2113 59.1731C13.4784 59.7131 12.696 60.0011 11.8047 60.0011C10.2399 60.0011 9.69525 59.0531 9.8438 57.9251C9.89332 57.3851 10.1409 56.9171 10.5767 56.4971C11.4086 55.6091 13.5676 55.4891 14.3995 54.7691C14.449 54.0011 14.2014 53.7011 13.419 53.7011C12.4881 53.7011 11.7552 54.0611 10.4281 55.1291L10.7252 52.6331C11.8542 51.6251 12.9337 51.2051 14.2014 51.2051C15.8157 51.2051 17.2418 51.9731 16.9447 54.1211L16.6476 56.5571C16.5486 57.3851 16.5485 57.6851 17.3309 57.6851L16.5684 59.9411ZM14.2212 56.1971C13.4883 56.7371 12.1117 56.6771 11.9632 57.8051C11.9137 58.3451 12.1612 58.7531 12.597 58.7531C13.0328 58.7531 13.5775 58.5131 13.9736 58.1531C13.9241 57.9731 13.9736 57.7331 14.0231 57.3851L14.2212 56.1971Z"
          fill={white ? "#FFFFFF" : "#235971"}
        />
        <path
          d="M19.1739 51.3856H21.9172L21.7687 52.5136C22.6501 51.6256 23.2839 51.2656 24.1753 51.2656C25.6905 51.2656 26.4333 52.3936 26.1857 54.2896L25.4429 59.8816H22.6996L23.2839 55.2496C23.383 54.4216 23.1849 54.0016 22.6501 54.0016C22.2044 54.0016 21.8182 54.3016 21.4221 54.8896L20.7882 59.8216H18.0449L19.1739 51.3856Z"
          fill={white ? "#FFFFFF" : "#235971"}
        />
        <path
          d="M28.3349 48.8887H31.0881L30.4047 54.2887L33.049 51.3727H36.436L33.049 54.9967L35.7428 59.8807H32.2666L30.1571 55.9567H30.1076L29.6124 59.8807H26.8691L28.3349 48.8887Z"
          fill={white ? "#FFFFFF" : "#235971"}
        />
        <path
          d="M1.42612 48.8887H6.72455C8.98258 48.8887 9.51737 50.2567 9.36882 51.5647C9.22026 52.5727 8.68547 53.3527 7.65549 53.8207C8.93306 54.4207 9.41834 55.3087 9.22026 56.7367C8.97267 58.5247 7.70501 59.8207 6.0313 59.8207H0L1.42612 48.8887ZM4.90229 53.4607C5.93226 53.4607 6.41754 52.8127 6.51658 52.0327C6.61561 51.2047 6.31851 50.6047 5.28853 50.6047H4.35759L3.96145 53.4607H4.90229V53.4607ZM4.31798 58.2127C5.39747 58.2127 5.98178 57.6727 6.13034 56.6047C6.22937 55.7167 5.83323 55.1167 4.80325 55.1167H3.77328L3.37713 58.2007L4.31798 58.2127Z"
          fill={white ? "#FFFFFF" : "#235971"}
        />
        <path
          d="M16.5684 59.9411C15.1918 60.0611 14.5084 59.8811 14.2113 59.1731C13.4784 59.7131 12.696 60.0011 11.8047 60.0011C10.2399 60.0011 9.69525 59.0531 9.8438 57.9251C9.89332 57.3851 10.1409 56.9171 10.5767 56.4971C11.4086 55.6091 13.5676 55.4891 14.3995 54.7691C14.449 54.0011 14.2014 53.7011 13.419 53.7011C12.4881 53.7011 11.7552 54.0611 10.4281 55.1291L10.7252 52.6331C11.8542 51.6251 12.9337 51.2051 14.2014 51.2051C15.8157 51.2051 17.2418 51.9731 16.9447 54.1211L16.6476 56.5571C16.5486 57.3851 16.5485 57.6851 17.3309 57.6851L16.5684 59.9411ZM14.2212 56.1971C13.4883 56.7371 12.1117 56.6771 11.9632 57.8051C11.9137 58.3451 12.1612 58.7531 12.597 58.7531C13.0328 58.7531 13.5775 58.5131 13.9736 58.1531C13.9241 57.9731 13.9736 57.7331 14.0231 57.3851L14.2212 56.1971Z"
          fill={white ? "#FFFFFF" : "#235971"}
        />
        <path
          d="M19.1739 51.3856H21.9172L21.7687 52.5136C22.6501 51.6256 23.2839 51.2656 24.1753 51.2656C25.6905 51.2656 26.4333 52.3936 26.1857 54.2896L25.4429 59.8816H22.6996L23.2839 55.2496C23.383 54.4216 23.1849 54.0016 22.6501 54.0016C22.2044 54.0016 21.8182 54.3016 21.4221 54.8896L20.7882 59.8216H18.0449L19.1739 51.3856Z"
          fill={white ? "#FFFFFF" : "#235971"}
        />
        <path
          d="M28.3349 48.8887H31.0881L30.4047 54.2887L33.049 51.3727H36.436L33.049 54.9967L35.7428 59.8807H32.2666L30.1571 55.9567H30.1076L29.6124 59.8807H26.8691L28.3349 48.8887Z"
          fill={white ? "#FFFFFF" : "#235971"}
        />
        <path
          d="M38.0492 48.8887H41.2381L39.812 59.8207H36.623L38.0492 48.8887Z"
          fill={white ? "#FFFFFF" : "#459BBE"}
        />
        <path
          d="M42.7536 48.8887H47.2597C50.7359 48.8887 51.7658 51.9727 51.4291 54.5287C51.132 57.0247 49.4682 59.8807 46.4278 59.8807H41.377L42.7536 48.8887ZM45.6949 57.2647C47.2102 57.2647 48.1015 56.3767 48.3392 54.4087C48.5373 52.9807 48.1411 51.5527 46.4773 51.5527H45.5959L44.863 57.3127L45.6949 57.2647Z"
          fill={white ? "#FFFFFF" : "#459BBE"}
        />
      </g>
      <defs>
        <clipPath id="clip0_206_43355">
          <rect width="52.3604" height="60" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
