import type { GetServerSideProps } from "next"
import dynamic from "next/dynamic"
import { useState } from "react"

import { Hero } from "@components/hero"
import { Layout } from "@components/layout"
import { LoginWithBankID } from "@components/login/LoginWithBankID"
import { Section } from "@components/section"
import { Wysiwyg } from "@components/wysiwyg"
import { gql } from "@urql/core"
import { parse, serialize } from "cookie"
import invariant from "tiny-invariant"

import { CORE_PAGE_FIELDS, GET_GLOBAL_FIELDS, WP_MEDIA_FIELDS } from "@/graphql-queries"
import { GQLGlobalFields, GQLLoggaInFields } from "@/models"
import client from "@/urql-client"
import { getAppLocale, isAuthValid } from "@/utils"

import styles from "./logga-in.module.scss"

const GET_LOGGA_IN_FIELDS = gql`
  ${WP_MEDIA_FIELDS}
  ${CORE_PAGE_FIELDS}
  query GetLoggaInFields($id: ID!) {
    page(id: $id, idType: DATABASE_ID) {
      ...CorePageFields
      acfLogin {
        login {
          title
          leading
          description
          loginImage {
            ...WPMediaFields
          }
        }
        loginForm {
          loginButtonLabel
          helpText
          contactUsLabel
          thankYouText
        }
        contactUsDialog {
          title
          description
          namePlaceholder
          ssnPlaceholder
          emailPlaceholder
          phonePlaceholder
          gdprText
          submitLabel
          submitLoadingLabel
          errorRequired
          errorInvalid
        }
      }
    }
  }
`

export const getServerSideProps: GetServerSideProps<LoggaInProps> = async ({
  req,
  res,
  locale,
}) => {
  // ensure necessary env vars exist
  invariant(process.env.WP_PAGEID_LOGGA_IN, "WP_PAGEID_LOGGA_IN is undefined")
  invariant(process.env.WP_PAGEID_LOGGA_IN_EN, "WP_PAGEID_LOGGA_IN_EN is undefined")
  invariant(process.env.API_ACCESS_TOKEN_KEY, "API_ACCESS_TOKEN_KEY is undefined")
  invariant(process.env.NEXT_PUBLIC_I18N_KEY, "NEXT_PUBLIC_I18N_KEY is undefined")

  // parse request cookies
  const cookies = parse(req.headers.cookie ?? "")

  // redirect to the locale if 'app-locale' cookie exists
  const appLocale = getAppLocale(locale)
  const cookieLocale = getAppLocale(cookies[process.env.NEXT_PUBLIC_I18N_KEY])
  if (appLocale !== cookieLocale) {
    const routePrefix = cookieLocale === "se" ? "" : "/" + cookieLocale
    return {
      redirect: {
        destination: routePrefix + "/logga-in",
        permanent: false,
      },
    }
  }

  // redirect to medlemsprovning page if a valid access token is detected
  const accessToken = cookies[process.env.API_ACCESS_TOKEN_KEY]
  if (isAuthValid(accessToken))
    return {
      redirect: {
        destination: process.env.NEXT_PUBLIC_MEDLEM_URL!,
        permanent: false,
      },
    }

  // remove access token cookie from user's browser
  res.setHeader(
    "Set-Cookie",
    serialize(process.env.API_ACCESS_TOKEN_KEY, "", {
      httpOnly: true,
      secure: process.env.APP_ENV === "staging" || process.env.APP_ENV === "production",
      domain: process.env.API_COOKIE_DOMAIN,
      path: "/",
      sameSite: "strict",
      maxAge: 0,
    })
  )

  // determine correct page ID based on the passed locale
  const _locale = getAppLocale(locale)
  const pageId =
    _locale === "en" ? process.env.WP_PAGEID_LOGGA_IN_EN : process.env.WP_PAGEID_LOGGA_IN

  // fetch global acf and page-specific data concurrently
  const [{ data: globalFields }, { data: loggaIn }] = await Promise.all([
    client.query<GQLGlobalFields>(GET_GLOBAL_FIELDS, {}).toPromise(),
    client.query<GQLLoggaInFields>(GET_LOGGA_IN_FIELDS, { id: pageId }).toPromise(),
  ])

  // ensure necessary data exist
  invariant(globalFields, "globalFields is undefined")
  invariant(loggaIn, "loggaIn is undefined")

  return {
    props: {
      globalFields,
      loggaIn,
    },
  }
}

interface LoggaInProps {
  globalFields: GQLGlobalFields
  loggaIn: GQLLoggaInFields
}

const ContactFormDialog = dynamic(() => import("../components/form-contact-us/ContactFormDialog"), {
  loading: () => <p>loading...</p>,
})

export default function LoggaIn({ globalFields, loggaIn: { page: props } }: LoggaInProps) {
  const { seo, acfLogin } = props
  const { title, leading, description, loginImage } = acfLogin.login
  const { loginButtonLabel, helpText, contactUsLabel, thankYouText } = acfLogin.loginForm
  const [dialogOpen, setDialogOpen] = useState(false)
  const [formSuccess, setFormSuccess] = useState(false)
  const handleFormSuccess = (isSuccess: boolean) => {
    setFormSuccess(isSuccess)
    setDialogOpen(false)
  }

  return (
    <>
      <Layout seo={seo} globalFields={globalFields}>
        <Hero
          className={styles.hero}
          featuredImage={loginImage}
          imageHeight={200}
          hero={{ title: title, description: leading }}
        />

        <Section style={{ overflowX: "visible" }}>
          <div className={styles.container}>
            {/* Medlemsprovning Text */}
            <div className={styles.loginText}>
              <Wysiwyg content={description} />
            </div>

            {/* Login Form */}
            <div className={styles.rightSide}>
              <LoginWithBankID
                loginButtonLabel={loginButtonLabel}
                thankYouText={thankYouText}
                contactUsLabel={contactUsLabel}
                helpText={helpText}
                acfContactUs={acfLogin.contactUsDialog}
                onHelpClick={() => setDialogOpen(true)}
                showThankYou={formSuccess}
              />
            </div>
          </div>
        </Section>
      </Layout>

      {/* Lazy-load contact form dialog */}
      <ContactFormDialog
        {...acfLogin.contactUsDialog}
        open={dialogOpen}
        onClose={handleFormSuccess}
      />
    </>
  )
}
