import { useEffect, useState } from "react"

import classNames from "classnames"

import { IconLoading } from "@/logos/icon-loading"
import { LogoBankID } from "@/logos/logo-bankid"
import type { StartOIDCResponse } from "@/models"

import styles from "./LoginEmbed.module.scss"

const href = process.env.NEXT_PUBLIC_API_HOST + "/api/authentication/v5/oidc/start"
const targetUrl = `__origin__/callbacks/bankid-signin`
const params = new URLSearchParams({ targetUrl, state: "abc", lang: "en" })
const urlTemplate = `${href}?${params.toString()}`

interface LoginEmbedProps {
  loginLabel: string
  showIframe: boolean
  onLogin: () => void
  className?: string
}

export const LoginEmbed = ({ loginLabel, showIframe, onLogin, className }: LoginEmbedProps) => {
  const [iframeLoaded, setIframeLoaded] = useState(false)

  // create a one-off state that "calculates" the auth url for the iframe
  const [url, _] = useState(() =>
    urlTemplate.replace(
      "__origin__",
      typeof window === "undefined" ? process.env.APP_HOST! : window.location.origin
    )
  )

  useEffect(() => {
    const handler = (event: MessageEvent<string>) => {
      console.log(event)
      // only process same-origin events
      if (event.origin !== window.location.origin || typeof event.data !== "string") return

      // parse callback data and unbind message events
      let payload: StartOIDCResponse
      try {
        payload = JSON.parse(event.data)
      } catch (_) {
        // skip event handling
        // since could not parse event data as JSON
        return
      }

      // ensure event target is ours
      if (payload.target !== "bosam-signin") return

      // reload login page to pass the newly acquired auth code
      // to the API handler and to wait for redirection
      if (payload.resultStatus === "success") {
        window.location.href = "/callbacks/to-medlemsprovning"
      }
      // error path
      else {
        console.error("bankid authentication error")
      }
    }

    window.addEventListener("message", handler, false)
    return () => window.removeEventListener("message", handler, false)
  }, [])

  return (
    <div
      className={classNames(showIframe ? styles.containerWithIframe : styles.container, className)}
    >
      {showIframe ? (
        <>
          {!iframeLoaded && (
            <div className={styles.loader}>
              <IconLoading />
            </div>
          )}
          <iframe src={url} className={styles.iframe} onLoad={() => setIframeLoaded(true)} />
        </>
      ) : (
        <>
          <LogoBankID className={styles.logoBankID} />
          <button className={styles.btnLogin} onClick={onLogin}>
            <LogoBankID className={styles.btnLogoBankID} white />
            {loginLabel}
          </button>
        </>
      )}
    </div>
  )
}
