import dynamic from "next/dynamic"
import { useRef, useState } from "react"

import { LogoBankID } from "@/logos/logo-bankid"
import type { ACFLogin, StartOIDCResponse } from "@/models"

import styles from "./LoginPopup.module.scss"

interface LoginPopupProps {
  className?: string
  loginLabel: string
  tips: string
  contactUsLabel: string
  contactUsThankYou: string
  contactUs: ACFLogin["contactUsDialog"]
}

const popupWidth = 650
const popupHeight = 500
const DynamicContactFormDialog = dynamic(() => import("../form-contact-us/ContactFormDialog"))

export const LoginPopup = ({
  className,
  loginLabel,
  tips,
  contactUsLabel,
  contactUsThankYou,
  contactUs,
}: LoginPopupProps) => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const [formSuccess, setFormSuccess] = useState(false)
  const popupRef = useRef<Window | null>(null)

  // handlers
  const handleLogin = () => {
    // bankid auth url props
    const href = process.env.NEXT_PUBLIC_API_HOST + "/api/authentication/v5/oidc/start"
    const targetUrl = `${window.location.origin}/callbacks/bankid-signin`
    const params = new URLSearchParams({ targetUrl, state: "abc", lang: "en" })
    const url = `${href}?${params.toString()}`

    // popup window props
    const top = window.innerHeight / 2 - popupHeight / 2
    const left = window.innerWidth / 2 - popupWidth / 2
    const features = `width=${popupWidth},height=${popupHeight},resizable=yes,top=${top},left=${left}`

    // initialize a new popup window
    if (!popupRef.current || popupRef.current.closed) {
      popupRef.current = window.open(url, undefined, features)
      const handler = (event: MessageEvent<string>) => {
        // only process same-origin events
        if (event.origin !== window.location.origin || typeof event.data !== "string") return

        // parse callback data and unbind message events
        let payload: StartOIDCResponse
        try {
          payload = JSON.parse(event.data)
        } catch (_) {
          // skip event handling
          // since could not parse event data as JSON
          return
        }

        // ensure event target is ours
        if (payload.target !== "bosam-signin") return

        // remove event listener and close popup
        window.removeEventListener("message", handler, false)
        popupRef.current?.close()

        // reload login page to pass the newly acquired auth code
        // to the API handler and to wait for redirection
        if (payload.resultStatus === "success") {
          window.location.href = "/callbacks/to-medlemsprovning"
        }
        // error path
        else {
          console.error("bankid authentication error")
        }
      }
      window.addEventListener("message", handler, false)
    }

    // bring popup window to front
    popupRef.current?.focus()
  }
  const handleFormSuccess = (isSuccess: boolean) => {
    setFormSuccess(isSuccess)
    setDialogOpen(false)
  }

  return (
    <div className={className}>
      <div>
        <LogoBankID className={styles.logoBankID} />
        <button className={styles.btnLogin} onClick={handleLogin}>
          <LogoBankID className={styles.btnLogoBankID} white />
          {loginLabel}
        </button>
        <div className={styles.tips}>
          {formSuccess ? (
            <span>{contactUsThankYou}</span>
          ) : (
            <>
              <span>{tips}</span>
              <button onClick={() => setDialogOpen(true)}>{contactUsLabel}</button>
            </>
          )}
        </div>
      </div>
      <DynamicContactFormDialog {...contactUs} open={dialogOpen} onClose={handleFormSuccess} />
    </div>
  )
}
