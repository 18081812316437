import { useState } from "react"

import { LoginEmbed, LoginPopup } from "@components/login"
import { useDetectBrowser } from "@hooks/shared"

import { ACFLogin } from "@/models/pages"

import styles from "./LoginWithBankID.module.scss"

interface LoginWithBankIDProps {
  loginButtonLabel: string
  thankYouText: string
  contactUsLabel: string
  helpText: string
  showThankYou?: boolean
  acfContactUs: ACFLogin["contactUsDialog"]
  onHelpClick?: () => void
}

export const LoginWithBankID = ({
  loginButtonLabel,
  thankYouText,
  contactUsLabel,
  helpText,
  showThankYou,
  acfContactUs,
  onHelpClick,
}: LoginWithBankIDProps) => {
  const browser = useDetectBrowser()
  const [showIframe, setShowIframe] = useState(false)

  // on ios devices, we want to retain the login with bankid popup
  if (browser?.os === "iOS")
    return (
      <LoginPopup
        className={styles.loginPopupWrapper}
        loginLabel={loginButtonLabel}
        tips={helpText}
        contactUsLabel={contactUsLabel}
        contactUsThankYou={thankYouText}
        contactUs={acfContactUs}
      />
    )

  // on other devices, we want to show the bankid login wrapped in an iframe
  return (
    <>
      <LoginEmbed
        className={styles.loginIframeWrapper}
        loginLabel={loginButtonLabel}
        showIframe={showIframe}
        onLogin={() => setShowIframe(true)}
      />
      <div className={styles.loginIframeWrapperTips}>
        {showThankYou ? (
          <span>{thankYouText}</span>
        ) : (
          <>
            <span>{helpText}</span>
            <button onClick={onHelpClick}>{contactUsLabel}</button>
          </>
        )}
      </div>
    </>
  )
}
